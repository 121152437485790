<template>
    <el-dialog :visible.sync="visible" title="查看" :append-to-body="true">
        <el-form :model="dataForm">
            <el-descriptions :column="1" border>
                <el-descriptions-item label="维护人">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        维护人
                    </template>

                    <el-form-item prop="whr">
                        <el-input v-model="dataForm.whr" placeholder="请输入维护人" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="质检人">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        质检人
                    </template>

                    <el-form-item prop="zjr">
                        <el-input v-model="dataForm.zjr" placeholder="请输入质检人" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="进厂时间">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        进厂时间
                    </template>

                    <el-form-item prop="intoDate">
                        <el-date-picker v-model="dataForm.intoDate" type="datetime" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="请输入进场时间"></el-date-picker>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="出厂时间">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        出厂时间
                    </template>

                    <el-form-item prop="outDate">
                        <el-date-picker v-model="dataForm.outDate" type="datetime" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd HH:mm:ss" placeholder="请输入出场时间"></el-date-picker>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="里程">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        里程
                    </template>

                    <el-form-item prop="lc">
                        <el-input v-model="dataForm.lc" placeholder="请输入历程" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

                <el-descriptions-item label="维护项目">
                    <template slot="label">
                        <span class="requireSyb">*</span>
                        维护项目
                    </template>

                    <el-form-item prop="whxm">
                        <el-input v-model="dataForm.whxm" placeholder="维护项目" clearable></el-input>
                    </el-form-item>
                </el-descriptions-item>

            </el-descriptions>

        </el-form>

        <span slot="footer">
            <el-button @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                id: '',
                cid: '',
                whr: '',
                zjr: '',
                intoDate: '',
                outDate: '',
                lc: '',
                whxm: '',
            }
        }
    },
    methods: {
        init(carId, id) {
            this.initData()
            this.visible = true;

            if (carId) {
                this.dataForm.cid = carId
            }
            if (id) {
                this.dataForm.id = id;
                this.getMaintainInfo()
            }
        },
        initData() {
            Object.keys(this.dataForm).forEach(key => {
                this.dataForm[key] = ''
            })

        },
        // 新增维护记录
        addMaintainHandler() {
            const _this = this;

            this.$http.carmaintain.save(this.dataForm).then(res => {
                this.$message({
                    message: '新增成功',
                    type: 'success',
                    duration: 1000,
                    onClose() {
                        _this.visible = false;
                        _this.$emit('refreshDataList')
                    }
                })
            })
        },

        updateMaintainHandler() {
            const _this = this;

            this.$http.carmaintain.update(this.dataForm).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    duration: 1000,
                    onClose() {
                        _this.visible = false;
                        _this.$emit('refreshDataList')
                    }
                })
            })
        },

        // 获取维护记录信息
        getMaintainInfo() {
            this.$http.carmaintain.info({ id: this.dataForm.id }).then(res => {
                this.dataForm = res.data || this.dataForm;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
:deep() {
    .el-descriptions {
        .el-descriptions-item__label {
            width: 100px;
            text-align: center;
            color: #606266;
        }

        .el-descriptions-item__content {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .el-select {
        width: 100%;
    }

    .el-date-editor {
        width: 100%;
    }

    .el-form-item {
        margin-bottom: 0;
    }
}
</style>