<template>
    <div class="comp-maintain">
        <el-card>
            <div slot="header">维护记录</div>

            <el-table :data="propCarMaintains" border>
                <el-table-column label="序号" type="index" align="center" width="50"></el-table-column>
                <el-table-column label="维护人" prop="whr" align="center"></el-table-column>
                <el-table-column label="质检人" prop="zjr" align="center"></el-table-column>
                <el-table-column label="进厂时间" prop="intoDate" align="center"></el-table-column>
                <el-table-column label="出厂时间" prop="outDate" align="center"></el-table-column>
                <el-table-column label="里程" prop="lc" align="center"></el-table-column>
                <el-table-column label="维护项目" prop="whxm" align="center"></el-table-column>

                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="addOrUpdateHandle(scope.row.id)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <!-- 新增or修改组件 -->
        <add-or-update v-if="aUpd_visible" ref="AddOrUpdateDom" @refreshDataList="$emit('refreshCarInfo')" />
    </div>
</template>

<script>
import AddOrUpdate from './maintain-add-or-update'

export default {
    components: {
        AddOrUpdate
    },
    data() {
        return {
            aUpd_visible: false,  // 新增or修改组件显示状态
        }
    },
    props: ['propCarMaintains', 'carId'],
    methods: {
        // 新增维护记录
        addOrUpdateHandle(id) {
            this.aUpd_visible = true;

            this.$nextTick(() => {
                this.$refs['AddOrUpdateDom'].init(this.carId, id)
            })
        }
    }
}
</script>